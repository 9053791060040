@media (min-width: 768px) { 
  .topbar {
    min-height:42px;
  }
 .topbar .topbar__left  {
   max-width:calc( 100% - 210px);
   overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  float:left;

 }
 .topbar__news {
  width:auto;
 }
}
